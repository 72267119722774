import { Dialog, IconButton } from "@mui/material";
import React from "react";
import { CloseIcon } from "./Icons";

export default function MyDialog({ maxWidth, open, handleClose, children, top, sx = null }) {
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={() => {
        handleClose();
      }}
      sx={
        sx || {
          overflowY: "auto",
          "& .MuiDialog-container": { height: "auto" },
          "& .MuiPaper-root": {
            overflowY: "unset",
            maxHeight: "unset !important",
            margin: "30px auto"
          },
          "& .MuiDialogContent-root": { overflowY: "unset" },
          "& .MuiDialogActions-root": {
            bgcolor: "#fff",
            borderTop: 1,
            borderColor: "#d5d4d4",
            bottom: "0",
            padding: "16px 20px",
            borderRadius: "0 0 4px 4px"
          }
        }
      }
    >
      <IconButton
        sx={{ position: "absolute", top: top ? top : "5px", right: "5px" }}
        onClick={() => {
          handleClose();
        }}
      >
        <CloseIcon width="25px" height="25px" color="rgba(0, 0, 0, 0.54)" />
      </IconButton>
      {children}
    </Dialog>
  );
}
