export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,32}$/;
// eslint-disable-next-line no-useless-escape
export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/gm;
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const numberRegex = /^[0-9]+$/;
export const priceRegex = /^\d+(?:\.\d+)?(?:,\d+(?:\.\d+)?)*$/;
export const youTubeRegex =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const stringToSlug = (str) => {
  return str
    .trim()
    .toLowerCase()
    .replaceAll(" ", "-")
    .replaceAll(".", "-")
    .replaceAll("/", "-")
    .replaceAll("&", "-")
    .replaceAll(/^-+|-+$/g, "");
};
export const stringFormat = (str) => {
  return str.trim().replaceAll("_", " ");
};
export const dicimalregex = /^\d+(\.\d{0,2})?$/;

export const isContainsUppercase = /^(?=.*[A-Z]).*$/;
export const isContainsLowercase = /^(?=.*[a-z]).*$/;
export const isContainsNumber = /^(?=.*[0-9]).*$/;
export const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
export const isValidLength = /^.{8,32}$/;
