import { TicketReportType } from "./type";

// Organizers intial state
const initialAuthState = {
  reportLoading: false,
  error: null,
  ticketReportList: []
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case TicketReportType.GET_TICKET_REPORT_REQUEST:
      return { ...state, reportLoading: true };
    case TicketReportType.GET_TICKET_REPORT_SUCCESS:
      return { ...state, reportLoading: false, ticketReportList: action.payload.data };
    case TicketReportType.GET_TICKET_REPORT_FAIL:
      return { ...state, reportLoading: false, error: action.error };
    default:
      return state;
  }
};
