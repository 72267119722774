export const NotifyType = {
  SIMPLE_EVENT_LIST_REQUEST: "SIMPLE_EVENT_LIST_REQUEST",
  SIMPLE_EVENT_LIST_SUCCESS: "SIMPLE_EVENT_LIST_SUCCESS",
  SIMPLE_EVENT_LIST_FAIL: "SIMPLE_EVENT_LIST_FAIL",
  CUSTOMER_COUNTS_API_REQUEST: "CUSTOMER_COUNTS_API_REQUEST",
  CUSTOMER_COUNTS_API_SUCCESS: "CUSTOMER_COUNTS_API_SUCCESS",
  CUSTOMER_COUNTS_API_FAIL: "CUSTOMER_COUNTS_API_FAIL",
  SYNC_CUSTOMER_REQUEST: "SYNC_CUSTOMER_REQUEST",
  SYNC_CUSTOMER_SUCCESS: "SYNC_CUSTOMER_SUCCESS",
  SYNC_CUSTOMER_FAIL: "SYNC_CUSTOMER_FAIL",
  SEND_NOTIFICATION_TO_CUSTOMER_REQUEST: "SEND_NOTIFICATION_TO_CUSTOMER_REQUEST",
  SEND_NOTIFICATION_TO_CUSTOMER_SUCCESS: "SEND_NOTIFICATION_TO_CUSTOMER_SUCCESS",
  SEND_NOTIFICATION_TO_CUSTOMER_FAIL: "SEND_NOTIFICATION_TO_CUSTOMER_FAIL",
  GET_STATE_CITY_REQUEST: "GET_STATE_CITY_REQUEST",
  GET_STATE_CITY_SUCCESS: "GET_STATE_CITY_SUCCESS",
  GET_STATE_CITY_FAIL: "GET_STATE_CITY_FAIL"
};
