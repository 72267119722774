import { NotifyType } from "./type";

// Auth intial state
const initialAuthState = {
  notiFicationEventList: null,
  notificationLoading: false,
  customerLoading: false,
  customersCount: 0,
  sendNotificationLoading: false,
  stateCityLoading: false,
  states: [],
  cites: []
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case NotifyType.SIMPLE_EVENT_LIST_REQUEST:
      return { ...state, notificationLoading: true };
    case NotifyType.SIMPLE_EVENT_LIST_SUCCESS:
      return { ...state, notificationLoading: false, notiFicationEventList: action.payload.data };
    case NotifyType.SIMPLE_EVENT_LIST_FAIL:
      return { ...state, notificationLoading: false, notiFicationEventList: null };
    case NotifyType.CUSTOMER_COUNTS_API_REQUEST:
      return { ...state, customerLoading: true };
    case NotifyType.CUSTOMER_COUNTS_API_SUCCESS:
      return { ...state, customerLoading: false, customersCount: action?.payload?.data?.customerCount };
    case NotifyType.CUSTOMER_COUNTS_API_FAIL:
      return { ...state, customerLoading: false, customersCount: 0 };
    case NotifyType.SEND_NOTIFICATION_TO_CUSTOMER_REQUEST:
      return { ...state, sendNotificationLoading: true };
    case NotifyType.SEND_NOTIFICATION_TO_CUSTOMER_SUCCESS:
      return { ...state, sendNotificationLoading: false };
    case NotifyType.SEND_NOTIFICATION_TO_CUSTOMER_FAIL:
      return { ...state, sendNotificationLoading: false, customersCount: 0 };
    case NotifyType.GET_STATE_CITY_REQUEST:
      return { ...state, stateCityLoading: true };
    case NotifyType.GET_STATE_CITY_SUCCESS:
      const key = action.payload.storeLocation;
      return { ...state, stateCityLoading: false, [key]: action.payload.data.data };
    case NotifyType.GET_STATE_CITY_FAIL:
      return { ...state, stateCityLoading: false };
    default:
      return state;
  }
};
